<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<RbEventHeader></RbEventHeader>

	2019-06-08	init

-->

<template>
	<div class="RbEventHeader hSpace hSpace--app" :is="tag" :class="elmClasses">
		<div class="RbEventHeader__inner rbBorder rbBorder--top rbBorder--bottom">
			<!-- für events nowAndFuture wird das jahr nicht ausgegeben -->
			<template v-if="range === 'nowAndFuture'">
				<div class="RbEventHeader__dateTime flex flex--row flex--bottom font--sans font--sizeBig">
					<div>
						<span class="RbEventHeader__date">{{theEndDate}}</span>
					</div>
					<div>
						<span class="RbEventHeader__time">{{theEndTime}}</span>
					</div>
				</div>
			</template>
			<!-- für events in der vergangenheit wird das jahr mit ausgegeben -->
			<template v-else>
				<div class="RbEventHeader__dateTime flex flex--column flex--bottom font--sans font--sizeBig">
					<div class="RbEventHeader__date">{{theEndDate}}</div>
					<div class="RbEventHeader__time">{{theEndTime}}</div>
				</div>
			</template>
			<template v-if="link">
				<router-link :to="app.getLinkWithoutHostname(link)"
					class="RbEventHeader__title flex flex--top flex--spaceBetween richText handleHyphens font--serif font--sizeBig">
					<span v-html="title"></span>
					<span class="color color--textGold">›</span>
				</router-link>
			</template>
			<template v-else>
				<div class="RbEventHeader__title flex flex--bottom richText handleHyphens font--serif font--sizeBig" v-html="title"></div>
			</template>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'RbEventHeader',
		components: {},
		mixins: [],
		props: {
			tag: {
				type    : [String, Boolean],
				default : "div",
			},
			endDate: {
				type    : [String, Boolean],
				default : "",
			},
			endTime: {
				type    : [String, Boolean],
				default : "",
			},
			title: {
				type    : [String, Boolean],
				default : "",
			},
			link: {
				type    : [String, Boolean],
				default : "",
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			range(){
				return this.$route.query.filter === 'past' ? 'past' : 'nowAndFuture'
			},
			theEndDate(){
				let date = this.endDate

				const year = date.substring(0, 4)
				const month = date.substring(4, 6)
				const day = date.substring(6, 8)

				// für events in der vergangenheit wird das jahr mit ausgegeben
				if( this.range === 'nowAndFuture' ){
					date = date ? day + '.' + month : ''
				}
				else{
					date = date ? day + '.' + month + '.' + year : ''
				}

				return date
			},
			theEndTime(){
				let time = this.endTime

				const hours = time.substring(0, 2)
				const minutes = time.substring(2, 4)

				time = time ? hours + ':' + minutes : ''

				return time
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.RbEventHeader { // debug
		//background-color: fade( red, 25 );

		//&__inner { background-color: fade( red, 25 ); }
	}
	.RbEventHeader { // layout
		&__inner {
			.grid;
			.grid--cols-12;
			.grid--colGap;
		}
		&__dateTime {
			grid-column: span 4;

			& > * {
				//background-color: fade( red, 25 );
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
			@media @mq[dt] {}
			@media @mq[md] {
				grid-column: span 12;
			}
		}
		&__title {
			grid-column: 6 / span 7;

			@media @mq[md] {
				grid-column: span 12;
			}
		}
	}
	.RbEventHeader { // styling
		margin-top: -2px;
		margin-bottom: -2px; // remove double borders

		&__inner {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}

		&__time {
			@media @mq[md] {
				padding-left: 0.5em;
			}
		}


		a {
			transition: all @transitions[linkTransitionDuration] ease;
			color: @swatches[textBlack];
			//text-decoration: underline;
			&:hover{
				color: @swatches[textGold];
				//text-decoration: underline;
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
