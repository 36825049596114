<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="RbAppHeader hSpace hSpace--app" :class="elmClasses">
		<div class="RbAppHeader__inner rbBorder rbBorder--bottom">
			<div class="RbAppHeader__row height height--appHeaderRow rbBorder rbBorder--top">
				<div class="RbAppHeader__logo font font--serif flex flex--middle">
					<router-link class="RbAppHeader__logoLink" :to="'/'">
						<div class="RbAppHeader__logoRotor font font--sizeHuge">ROTOR</div>
						<div class="RbAppHeader__logoBooks font font--sizeHuge">BOOKS</div>
					</router-link>
				</div>
				<div class="RbAppHeader__addressText font font--sizeDefault font--serif richText flex flex--middle"
					 v-if="$root.isAboveMd">
					<div v-html="textRight"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import { EventBus } from '@/event-bus.js'
	//import RbShopMixin from './RbShop/RbShop.mixin.js'

	export default {
		name: 'RbAppHeader',
		components: {},
		mixins: [],
		props: {
			textRight: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.RbAppHeader { // debug
		[showBorders2] & {
			background-color: fade( red, 25 );
			.label('RbAppHeader', red );
			//font-size: 4em;
			//line-height: 1em;

			&__row {
				background-color: fade( red, 25 );
			}
		}
	}
	.RbAppHeader { // layout
		position: relative;
		padding-top: 1rem;

		&__row {
			.grid;
			.grid--cols-2;
			.grid--colGap;

			//height: 185px;
		}

		@media @mq[md] {
			//background-color: fade(red, 20);

			&__addressText { display: none; }
		}
	}
	.RbAppHeader { // styles
		&__logo {
			&Link {
				transition: all @transitions[linkTransitionDuration] ease;
				color: inherit;

				&:hover { color: @swatches[textGold]; }
			}
			&Rotor {
				text-indent: 1.1em;
				transform: translateY(15%); // fix vertical align
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
