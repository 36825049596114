import Vue from 'vue'
import Router from 'vue-router'
import RbHomeView from './views/RbHomeView.vue'

Vue.use(Router)

export default new Router({
	base: '/',
	mode: 'history',
	linkActiveClass: 'routerLink--active',
	linkExactActiveClass: 'routerLink--exactActive',
	routes: [
		{
			path: '/',
			name: 'RbHomeView',
			pathToRegexpOptions: { strict: true },
			component: RbHomeView,
		},
		{
			path: '/dev/',
			name: 'DevView',
			pathToRegexpOptions: { strict: true },
			component: () => import(/* webpackChunkName: "DevView" */ './views/DevView.vue'),
		},
		{
			path: '/books/',
			name: 'RbShopView',
			pathToRegexpOptions: { strict: true },
			component: () => import(/* webpackChunkName: "RbShopView" */ './views/RbShopView.vue'),
		},
		{
			path: '/books/:slug/',
			name: 'RbProductView',
			pathToRegexpOptions: { strict: true },
			component: () => import(/* webpackChunkName: "RbProductView" */ './views/RbProductView.vue'),
		},
		{
			path: '/events/',
			name: 'RbEventsView',
			pathToRegexpOptions: { strict: true },
			component: () => import(/* webpackChunkName: "RbEventsView" */ './views/RbEventsView.vue'),
		},
		{
			path: '/events/:slug/',
			name: 'RbEventView',
			pathToRegexpOptions: { strict: true },
			component: () => import(/* webpackChunkName: "RbEventsDetailView" */ './views/RbEventView.vue'),
		},
		{
			path: '/cart/',
			name: 'RbCartView',
			pathToRegexpOptions: { strict: true },
			component: () => import(/* webpackChunkName: "RbCartView" */ './views/RbCartView.vue'),
		},
		{
			path: '/checkout/:slug/',
			name: 'RbCheckoutView',
			pathToRegexpOptions: { strict: true },
			component: () => import(/* webpackChunkName: "RbCartView" */ './views/RbCheckoutView.vue'),
		},
		{
			path: '/:slug/',
			name: 'RbPageView',
			pathToRegexpOptions: { strict: true },
			component: () => import(/* webpackChunkName: "RbPageView" */ './views/RbPageView.vue'),
		},
		{
			path: '*',
			name: 'NotFoundView',
			component: () => import(/* webpackChunkName: "NotFoundView" */ './views/NotFoundView.vue'),
		},
	],
})
