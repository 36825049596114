/*

  Mixin that handles Rb Shop read and write
  every read prop is exposed as computed value
  and every write action is exposed as method function

  2021-06-30	init

*/
//import appConfig from '@/../appConfig.js'
import { EventBus } from '@/event-bus.js'
import { mapGetters } from 'vuex'
import store from './store'
import RestHandler from "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js"
//import _ from 'lodash'

let isFirstMount = false

export default {
	mixins: [RestHandler],
	watch: {},
	data() {
		return {
			doLog: false,
			isSubmittingOrder: false,
			doSetLocalStorage: true,
			storageFormatVersion: 2,
		}
	},
	computed: {
		...mapGetters([
			'countCartItems',
			'cartItems',
			'customer',
			'productsBruttoPrice',
			'productsNettoPrice',
			'productsTaxesPrice',
			'getCustomerProp',
			'shippingTaxesPrice',
			'shippingNettoPrice',
			'shippingBruttoPrice',
			'totalNettoPrice',
			'totalBruttoPrice',
			'totalTaxesPrice',
			'isStepValid',
			'customerFields',
			'shippingOptions',
			'paymentOptions',
		]),
	},
	methods: {
		fixTypo(value){
			//value = this._.replace(' - ', '—', value)
			value = this._.replace( value, / - /g, ' – ' ) // Gedankenstrich
			value = this._.replace( value, / x /g, ' × ' ) // Mal-Zeichen

			return value
		},
		normalizeProduct ( dbProduct, doLog = false){
			let normalizedProduct = {}
			const id = this._.get( dbProduct, 'NUMMER' )

			// groupCollapsed group
			if (doLog) {
				console.group('RbShop.mixin.js • normalizeProduct( dbProduct )')
				console.log('product:', dbProduct)
				console.groupEnd()
			}

			// anhand dieser legende werden die mwst-werte aus dem db-export
			// in konkrete prozentsätze übersetzt
			const taxPercentages = {
				1: 0,
				2: 7,
				3: 19,
			}
			const taxPercentage = this._.get( taxPercentages, this._.get( dbProduct, 'MWST'), 0 )
			const bruttoPrice = parseFloat( this._.get( dbProduct, "VERPREIS" ) )
			const nettoPrice = this._.round( bruttoPrice / (taxPercentage / 100 + 1), 2 )

			normalizedProduct = {
				id : id,
				author : this._.trim( this._.get( dbProduct, 'AutorAll' ), ':' ) || undefined,
				title : this.fixTypo( this._.get( dbProduct, "TITEL" ) ),
				subTitle : this.fixTypo( this._.get( dbProduct, "UTITEL" ) ),
				nettoPrice : nettoPrice,
				taxPercentage : taxPercentage,
				bruttoPrice : bruttoPrice,
				descText : this.fixTypo( this._.get( dbProduct, "INFOEX" ) ),
				isbn : this._.get( dbProduct, "ISBN" ),
				year : this._.get( dbProduct, "JAHR" ),
				publisher : this._.get( dbProduct, "VERORT" ),
				pages : this._.get( dbProduct, "ABSEITEN" ),
				dimensions : this._.get( dbProduct, "FOREINB" ),
				coverImageUrl: '/wp-content/rotorbooks/picturesOutput/' + id + '.jpg',
				biblios : ''
			}

			// build biblios value
			let values = [
				normalizedProduct.publisher,
				normalizedProduct.dimensions,
				normalizedProduct.pages,
				'ISBN ' + normalizedProduct.isbn,
			]
			normalizedProduct.biblios = this._.compact( values ).join( "\n" )

			return normalizedProduct
		},
		addCartItem ( product, amount = 1, doLog = false) {
			// groupCollapsed group
			if (doLog) {
				console.groupCollapsed('RbShop.mixin.js • addCartItem( product, amount )')
				console.log('product:', product)
				console.log('amount:', amount)
				console.groupEnd()
			}

			this.$store.commit('addCartItem', {
				product,
				amount,
			})

			EventBus.$emit('MhNotification--show', 'Der Artikel wurde in den Warenkorb gelegt.')

			this.setLocalStorage()
		},
		removeCartItem ( itemId, doLog = false) {
			// groupCollapsed group
			if (doLog) {
				console.groupCollapsed('RbShop.mixin.js • removeCartItem( itemId )')

				console.log('itemId:', itemId)
				console.groupEnd()
			}

			this.$store.commit('removeCartItem', {
				itemId: itemId,
			})

			this.setLocalStorage()
		},
		changeCartItemAmount ( itemId, step = 0, doLog = false) {
			// groupCollapsed group
			if (doLog) {
				console.groupCollapsed('RbShop.mixin.js • changeCartItemAmount( itemId )')
				console.log('itemId:', itemId)
				console.log('step:', step)
				console.groupEnd()
			}

			this.$store.commit('changeCartItemAmount', {
				itemId: itemId,
				step: step,
			})

			this.setLocalStorage()
		},
		setLocalStorage ( doLog = false) {
			const cartItems = this.cartItems
			const customer = this.customer
			const doSetLocalStorage = this.doSetLocalStorage

			const storageData = {
				ts: Date.now(),
				cartItems: cartItems,
				customer: customer,
				version: this.storageFormatVersion,
			}

			// groupCollapsed group
			if (doLog && doSetLocalStorage) {
				console.groupCollapsed('RbShop.mixin.js • setLocalStorage()')
				console.log('cartItems:', cartItems)
				console.log('customer:', customer)
				console.log('storageData:', storageData)
				console.log('doSetLocalStorage:', doSetLocalStorage)
				console.groupEnd()
			}

			if( doSetLocalStorage ){
				localStorage.setItem('rbCart', JSON.stringify(storageData))
			}
		},
		getLocalStorage ( doLog = false) {
			// get localStorage on first load
			const rbCart = JSON.parse(localStorage.getItem('rbCart') ? localStorage.getItem('rbCart') : null)
			const cartTs = this._.get(rbCart, 'ts', 0)
			const cartItems = this._.get(rbCart, 'cartItems', [])
			const customer = this._.get(rbCart, 'customer', [])
			const version = this._.get(rbCart, 'version', 0)
			const isCorrectVersion = version === this.storageFormatVersion

			// groupCollapsed group
			if (doLog) {
				console.groupCollapsed('RbShop.mixin.js • getLocalStorage()')
				console.log('rbCart:', rbCart)
				console.log('cartTs:', cartTs)
				console.log('cartItems:', cartItems)
				console.log('customer:', customer)
				console.log('isCorrectVersion:', isCorrectVersion)
				console.groupEnd()
			}

			this.doSetLocalStorage = false

			// only storage from the same version
			// will be restored to prevent formating/structur issues
			// how the data is formated
			if( isCorrectVersion ){
				cartItems.forEach((item) => {
					const amount = item.amount
					const product = item.product

					this.addCartItem(product, amount)
				})

				this._.forEach(customer, (value, key) => {
					this.setCustomerProp(key, value)
				})
			}

			this.doSetLocalStorage = true
		},
		setCustomerProp (key, value) {
			this.$store.commit('setCustomerProp', {
				key: key,
				value: value,
			})

			this.setLocalStorage()
		},
		emptyCart ( doLog = false){
			const cartItems = this.cartItems
			const ids = this._.map(cartItems, 'product.id' )

			// groupCollapsed group
			if (doLog) {
				console.groupCollapsed('RbShop.mixin.js • emptyCart()')
				console.log('ids:', ids)
				console.groupEnd()
			}

			// remove all cartItems
			ids.forEach(id=>{
				this.removeCartItem(id)
			})

			// reset consents
			this.setCustomerProp('acceptedLegals', false)
			this.setCustomerProp('acceptedPrivacy', false)
			this.setCustomerProp('hasNewsletterSubscription', false)
		},
		getFormatedPrice( value ){ // float value to string '13,99'
			let formatedValue = value ? value : 0

			formatedValue = formatedValue.toFixed(2) // add zwei nachkommastellen
			formatedValue = formatedValue.toString().replace('.', ',') // replace dot with comma

			return formatedValue + '&nbsp;€'
		},
		submitOrder ( callbackFn, doLog = false) {
			// groupCollapsed group
			if (doLog) {
				console.groupCollapsed('RbShop.mixin.js • submitOrder()')
				console.groupEnd()
			}

			this.isSubmittingOrder = true

			this.restHandler__fetch({
				action: "POST",
				route: "/wp-json/mh/v1/rb__handleOrder",
				params: {
					ts: Date.now(), // prevent rest cache
				},
				data: {
					cart: this.cartItems,
					customer: this.customer,
					shipping: {
						title: this._.get(this._.find(this.shippingOptions, { key: this.getCustomerProp('shippingOption') }), 'label'),
						nettoPrice: this.shippingNettoPrice,
						bruttoPrice: this.shippingBruttoPrice,
					},
					billing: {
						totalNettoPrice: this.totalNettoPrice,
						totalTaxesPrice: this.totalTaxesPrice,
						totalBruttoPrice: this.totalBruttoPrice,
					},
				},
				callback: (response) => {
					this.isSubmittingOrder = false
					this.emptyCart()
					this.$router.push('/checkout/thank-you/')
				}
			})

			//setTimeout(()=>{ this.isSubmittingOrder = false }, 1000)

			//
		},
	},
	beforeCreate() {},
	created(doLog = false) {
		// register vuex module but only once
		const alreadyRegistered = this.$store.state.shop ? true : false

		if (!alreadyRegistered) {
			this.$store.registerModule('shop', store)
		}

		// groupCollapsed group
		if (doLog) {
			console.group('RbShop.mixin.js • created()')
			console.log('isFirstMount:', isFirstMount)
			console.groupEnd()
		}

		// get localStorage on first load
		if (!isFirstMount) {
			isFirstMount = true
			this.getLocalStorage()
		}
	},
	mounted() {},
}
