<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<RbAppFooter></RbAppFooter>

	2019-06-08	init

-->

<template>
	<div class="RbAppFooter hSpace hSpace--app" :class="elmClasses">
		<div class="RbAppFooter__inner">
			<div class="RbAppFooter__schwung"></div>
			<div class="RbAppFooter__letters font font--serif font--sizeDefault rbBorder rbBorder--bottom">
				<span data-letter="A"></span>
				<span data-letter="B"></span>
				<span data-letter="C"></span>
				<span data-letter="D"></span>
				<span data-letter="E"></span>
				<span data-letter="F"></span>
				<span data-letter="G"></span>
				<span data-letter="H"></span>
				<span data-letter="I"></span>
				<span data-letter="J"></span>
				<span data-letter="K"></span>
				<span data-letter="L"></span>
				<span data-letter="M"></span>
				<span data-letter="N"></span>
				<span data-letter="O"></span>
				<span data-letter="P"></span>
				<span data-letter="Q"></span>
				<span data-letter="R"></span>
				<span data-letter="S"></span>
				<span data-letter="T"></span>
				<span data-letter="U"></span>
				<span data-letter="V"></span>
				<span data-letter="W"></span>
				<span data-letter="X"></span>
				<span data-letter="Y"></span>
				<span data-letter="Z"></span>
			</div>
			<div class="RbAppFooter__text font font--serif font--sizeDefault rbBorder rbBorder--bottom">
				<div class="RbAppFooter__textLeft richText" v-html="textLeft"></div>
				<div class="RbAppFooter__textRight richText" v-html="textRight"></div>
			</div>
			<nav class="RbAppFooter__menu font font--sans rbBorder rbBorder--bottom"
				 :class="$root.isBelowDt ? 'font--sizeDefault' : 'font--sizeSmall'">
				<div class="RbAppFooter__menuLeft">
					<router-link class="RbAppFooter__menuLink noWrap" :to="'/'">Home</router-link>
					<router-link class="RbAppFooter__menuLink noWrap" :to="'/books/'">BOOKS</router-link>
					<router-link class="RbAppFooter__menuLink noWrap" :to="'/events/'">events</router-link>
					<router-link class="RbAppFooter__menuLink noWrap" :to="'/about/'">About</router-link>
					<router-link class="RbAppFooter__menuLink noWrap" :to="'/cart/'">Cart <span class="RbAppFooter__countCartItems">{{this.countCartItems}}</span></router-link>
				</div>
				<div class="RbAppFooter__menuRight">
					<router-link class="RbAppFooter__menuLink noWrap" :to="'/imprint/'">Imprint</router-link>
					<router-link class="RbAppFooter__menuLink noWrap" :to="'/privacy/'">Privacy</router-link>
					<router-link class="RbAppFooter__menuLink noWrap" :to="'/agb/'">Agb</router-link>
				</div>
			</nav>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import { EventBus } from '@/event-bus.js'
	import RbShopMixin from './RbShop/RbShop.mixin.js'

	export default {
		name: 'RbAppFooter',
		components: {},
		mixins: [
			RbShopMixin,
		],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			textLeft: {
				type     : [String],
				default  : '',
				required : false,
			},
			textRight: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			handleScroll( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.RbAppFooter { // debug
		/*
		& { background-color: fade( red, 25 ); }
		&__schwung { background-color: fade( blue, 25 ); }
		&__letters { background-color: fade( red, 25 ); }
		&__menu, &__text & > * { outline: 1px solid red; }
		*/
	}
	.RbAppFooter { // layout
		&__schwung {
			padding-bottom: 43%;
		}
		&__letters {
			.grid;
			grid-template-columns: repeat(26, 1fr);

			span { // single letter
				grid-column: span 1;
				display: inline-block;
				&::before { content: attr(data-letter); }
			}
		}
		&__menu, &__text {
			.grid;
			grid-template-columns: repeat(26, 1fr);

			&Left  { grid-column: 2  / span 15; }
			&Right { grid-column: 18 / span 10; }
		}

		@media @mq[md] {
			&__letters span {
				display: none;
			}
			&__text {
				display: flex;
				flex-direction: column;

				&Right { order: 1; }
				&Left { order: 2; }
			}
			&__menu {
				display: flex;
				flex-direction: column;
			}
			&__menuLink {
				display: inline-flex;
				width: 50%;
			}
		}
	}
	.RbAppFooter { // styling
		color: @swatches[textGold];
		pointer-events: none;
		padding-bottom: 2rem;

		&__inner {}

		&__schwung {
			background-position: 15% center;
			background-repeat: no-repeat;
			background-size: contain;
			background-image: url(./../assets/imgs/schwung.svg);
		}
		&__letters {
			padding-top: 0.5em;
			padding-bottom: 0.5em;
		}
		&__text {
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			pointer-events: auto;
		}
		&__menu {
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			pointer-events: auto;
		}

		&__menuLink {
			color: inherit;
			text-transform: uppercase;
			transition: all @transitions[linkTransitionDuration] ease;

			//&.routerLink--active { color: @swatches[textBlack]; }
			//&.routerLink--active { color: @swatches[textBlack]; }
			&.routerLink--exactActive { color: @swatches[textBlack]; }

			&:hover { color: @swatches[textBlack]; }
		}

		&__countCartItems {
			//background-color: yellow;

			display: inline-flex;
			justify-content: space-between;
			min-width: 1.5em;

			&::before { content: "("; }
			&::after  { content: ")"; }
		}

		@media @mq[dt] {
			margin-top: -10em;

			&__menuLink {
				& + & { padding-left: 1em; }
			}
		}
		@media @mq[md] {
			margin-top: -2em;

			&__letters {
				padding: 0.3em 0;
			}
			&__textLeft {
				padding-top: 1.2em;
			}
			&__menuLink {
				padding: 0.15em 0;
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
