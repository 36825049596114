<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<RbNewsRow></RbNewsRow>

	2019-06-08	init

-->

<template>
	<div class="RbNewsRow hSpace hSpace--app" :class="elmClasses">
		<div class="RbNewsRow__inner rbBorder rbBorder--bottom"
		     :class="index ? 'rbBorder--top' : ''">
			<div class="RbNewsRow__imageBox">
				<div class="RbNewsRow__imageWrapper">
					<MhImage v-if="image" :imageObject="image" :mode="'cover'"></MhImage>
				</div>
			</div>
			<div class="RbNewsRow__textBox richText handleHyphens">
				<h3 class="RbNewsRow__title font font--serif font--sizeBig" v-html="titleStr"></h3>
				<div class="RbNewsRow__text font font--sans font--sizeDefault" v-html="textStr"></div>
				<div class="RbNewsRow__date font font--sans font--sizeSmall" v-html="dateStr"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { EventBus } from '@/event-bus.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'RbNewsRow',
		components: {
			MhImage,
		},
		mixins: [],
		props: {
			image: {
				type    : [Object, Boolean],
				default : false,
			},
			title: {
				type    : [String, Boolean],
				default : false,
			},
			text: {
				type    : [String, Boolean],
				default : false,
			},
			date: {
				type    : [String, Boolean],
				default : false,
			},
			index: {
				type    : [Number],
				default : 0,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			titleStr(){
				return this.title
			},
			textStr(){
				return this.text
			},
			dateStr(){
				const date  = this.date
				let dateStr = ''

				if( date ){
					const parts = this.date.split('T')[0]
					const dateStrParts = parts.split('-')
					const year = parseInt( dateStrParts[0] )
					const month = parseInt( dateStrParts[1] )
					const monthName = this.app.monthNames[month-1]
					const day = parseInt( dateStrParts[2] )

					dateStr = day + '. ' + monthName + ' ' + year

					/*
					console.log('')
					console.log('dateStr:', dateStr)
					console.log('year:', year)
					console.log('month:', month)
					console.log('monthName:', monthName)
					console.log('day:', day)
					console.log('dateStr:', dateStr)
					*/
				}

				return dateStr
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.RbNewsRow { // debug
		//background-color: fade( red, 20 );

		//&__inner { background-color: fade( orange, 25 ); }
		//&__inner > * { outline: 1px solid fade( red, 25 ); }
	}
	.RbNewsRow { // layout
		&__inner {
			.grid;
			.grid--cols-12;
			.grid--colGap;
		}
		&__imageBox {
			grid-column: 1 / span 5;
		}
		&__textBox {
			grid-column: 7 / span 6;
		}

		@media @mq[md] {
			&__imageBox,
			&__textBox {
				grid-column: 1 / span 12;
			}
		}
	}
	.RbNewsRow { // styling
		margin-bottom: -2px; // remove double borders

		&__inner {
			padding-bottom: 3rem;
		}
		&__imageBox {}
		&__textBox {
			//padding-top: 1.5em;
			@media @mq[md] {
				//padding-top: 1em;
			}
		}

		&__imageWrapper {
			position: relative;
			padding-bottom: 100%;
			background-color: @swatches[lightGold]; // placeholder rect

			.MhImage {
				position: absolute;
				inset: 0;
				//display: none;
			}
		}
		&__title {
			padding-top: 0.75em;
			padding-bottom: 0.5em;
		}
		&__text {
			padding-bottom: 0.75em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
