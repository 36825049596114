<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhNotification></MhNotification>

	Die Componente wird via EventBus getriggert:
		EventBus.$emit('MhNotification--show', 'Die Mail-Adresse wurde erfolgreich übermittelt.')

	2021-11-09	improvement: add sample code for triggering
	2019-06-08	init

-->

<template>
	<div class="MhNotification" :class="elmClasses" @transitionend="onTransitionEvent">
		<div class="MhNotification__body">
			<div class="MhNotification__bodyText font font--sans font--sizeSmall" v-html="message"></div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'MhNotification',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			/*
			myProp: {
				type     : [String, Boolean],
				default  : false,
				required : false,
			},
			*/
		},
		data(){
			return {
				message : undefined,
				hideTimeout : undefined,
				isVisible : false,
			}
		},
		watch: {
			message: {
				handler: function( to, from ) {
					if( to ) this.isVisible = true

					clearTimeout( this.hideTimeout )

					this.hideTimeout = setTimeout(()=>{
						this.isVisible = false
					}, 2000)
				},
				immediate : false,
				deep: false,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isVisible ) classes.push( this.$options.name + '--isVisible')

				return classes
			},
		},
		methods: {
			onTransitionEvent( event, doLog = false ){
				const property = event.propertyName

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onTransitionEvent( event )' )
					console.log('event:', event)
					console.log('property:', property)
					console.groupEnd()
				}

				if( !this.isVisible ) this.message = ''
			},
		},
		created(){},
		mounted(){
			EventBus.$on('MhNotification--show', (message, doLog = false)=>{
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• on "show"' )
					console.log('message:', message)
					console.groupEnd()
				}

				this.message = message
			})
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.MhNotification { // vars
	}
	XXX.MhNotification { // debug
		background-color: fade( red, 50 );
	}
	.MhNotification { // layout
		position: fixed;
		top: 1em; left: 0; right: 0;
		transform: translateY( calc(-1em - 100%) );
		z-index: 100;

		&--isVisible {
			transform: translateY(0);
		}

		&__body {
			display: flex;
			justify-content: center;
		}
		&__bodyText {}
	}
	.MhNotification { // styling
		transition: all 0.2s ease;

		&--isVisible {
			transition-delay: 0.35s; // ux: short delay to fake doing something
		}

		&__bodyText {
			padding: 0.5em 1em;
			text-align: center;
			color: @swatches[bgWhite];
			background-color: @swatches[textBlack];
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
