<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<RbSectionTitleRow></RbSectionTitleRow>

	2019-06-08	init

-->

<template>
	<div class="RbSectionTitleRow hSpace hSpace--app" :class="elmClasses">
		<h2 class="RbSectionTitleRow__inner height height--sansRow flex flex--middle font font--sans font--sizeMedium font--uppercase rbBorder rbBorder--strong rbBorder--top"
			:class="$root.isBelowDt ? 'font--sizeDefault' : 'font--sizeMedium'"
			v-html="title"></h2>
		<div class="rbBorder rbBorder--bottom"></div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import { EventBus } from '@/event-bus.js'

	export default {
		name: 'RbSectionTitleRow',
		components: {},
		mixins: [],
		props: {
			title: {
				type    : [String, Boolean],
				default : "",
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.RbSectionTitleRow { // debug
		//background-color: fade( red, 20 );

		//&__inner { background-color: fade( red, 25 ); }
	}
	.RbSectionTitleRow { // layout
	}
	.RbSectionTitleRow { // styling
		margin-bottom: -2px; // remove double borders

		&__inner {}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
