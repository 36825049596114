<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<RbShopDebug></RbShopDebug>

	2019-06-08	init

-->

<template>
	<div class="RbShopDebug" :class="elmClasses">
		<strong>RbShopDebug</strong><br />
		<div class="cartItems">
			<div class="cartItems__item" v-for="(item, index) in cartItems" :key="index">
				{{ item.product.title }} • {{ item.amount }}
			</div>
		</div>
		<pre name="isStepValid">{{
			'cart:' + isStepValid('cart') + "\n" +
			'customer:' + isStepValid('customer') + "\n" +
			'finish:' + isStepValid('finish') + "\n"
		}}</pre>
		<button @click="showVars = !showVars">Show Vars</button>
		<div v-show-slide="showVars">
			<pre name="productsNettoPrice">{{ this.productsNettoPrice }}</pre>
			<pre name="productsTaxesPrice">{{ this.productsTaxesPrice }}</pre>
			<pre name="productsBruttoPrice">{{ this.productsBruttoPrice }}</pre>
			<pre name="countCartItems">{{ this.countCartItems }}</pre>
			<pre name="shippingOption">{{ this.getCustomerProp('shippingOption') }}</pre>
			<pre name="customer">{{ this.customer }}</pre>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import RbShopMixin from './RbShop.mixin.js'

	export default {
		name: 'RbShopDebug',
		components: {},
		mixins: [RbShopMixin],
		props: {},
		data() {
			return {
				showVars : false,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses() {
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
		destroyed() {},
	}
</script>

<style lang="less">
	@import (reference) '@/less/vars.less';
	@import (reference) '@/less/mixins.less';
	@import (reference) '@/less/atoms.less';

	// debug
	.RbShopDebug {
	}
	// layout
	.RbShopDebug {
		position: fixed;
		bottom: 2em;
		left: 2em;
		min-width: 200px;
		max-height: 300px;
		overflow: hidden;
		overflow-y: auto;
	}
	// styling
	.RbShopDebug {
		padding: 0.5em;

		line-height: 1.2em;
		font-size: 14px;
		background-color: fade(pink, 95);

		.cartItems {
			&__item { border-top: 1px solid black; padding: 0.25em 0; }
			&__item:last-child { border-bottom: 1px solid black;	}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
