<template>
	<MhRouterView class="RbHomeView view" :isContentReady="isContentReady">

		<RbAppHeader
			:textRight="_.get( app, 'acfOptions.headerTextRight' )"
		></RbAppHeader>

		<RbAppMenu></RbAppMenu>

		<article class="view__inner">

			<!-- Inhaltsmodule -->
			<RbContentModules
				:modules="_.get( post, 'acf.contentModules')"
			></RbContentModules>

			<!-- News -->
			<RbNewsRow
				v-for="(post, index) in newsPosts" :key="'n'+index"
				:image="_.get(post, 'acf.image')"
				:title="_.get(post, 'title.rendered')"
				:text="_.get(post, 'acf.text')"
				:date="_.get(post, 'date')"
				:index="index"
			></RbNewsRow>

			<!-- Events -->
			<RbSectionTitleRow
				:title="'Next Events'"
				v-if="_.size( eventPosts )"
			></RbSectionTitleRow>
			<RbEventHeader
				v-for="(post, index) in eventPosts" :key="'e'+index"
				:endDate="_.get( post, 'acf.endDate')"
				:endTime="_.get( post, 'acf.endTime')"
				:title="_.get(post, 'title.rendered')"
				:link="_.get(post, 'link')"
			></RbEventHeader>

			<RbNewsletterWidget />

			<!--
			<pre name="eventPosts">{{eventPosts}}</pre>
			<pre name="newsPosts">{{newsPosts}}</pre>
			-->

		</article>

	</MhRouterView>
</template>

<script>
	import { EventBus } from '@/event-bus.js'
	import RestHandler from "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js"
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterView.vue'

	import RbAppHeader from "@/components/RbAppHeader.vue";
	import RbAppMenu from "@/components/RbAppMenu.vue";
	import RbContentModules from "@/components/RbContentModules.vue";
	import RbNewsRow from "@/components/RbNewsRow.vue";
	import RbNewsletterWidget from "@/components/RbNewsletterWidget.vue";
	import RbSectionTitleRow from "@/components/RbSectionTitleRow.vue";
	import RbEventHeader from "@/components/RbEventHeader.vue";
	import RbAppFooter from "@/components/RbAppFooter.vue";

	export default {
		name: "RbHomeView",
		components: {
			MhRouterView,
			RbAppHeader,
			RbAppMenu,
			RbContentModules,
			RbNewsRow,
			RbNewsletterWidget,
			RbSectionTitleRow,
			RbEventHeader,
			RbAppFooter,
		},
		mixins: [RestHandler],
		directives: {},
		props: {},
		data() {
			return {
				isContentReady: false,
				post: undefined,
				newsPosts: undefined,
				eventPosts: undefined
			};
		},
		computed: {
			app() {
				return this.$root.$children[0];
			},
			elmClasses() {
				let classes = [];

				//classes.push('new_class')

				return classes;
			}
		},
		methods: {
			setCssVars() {
				const root = document.documentElement;
				root.style.overflow = "auto";
				root.style.height = "101vh";
				const scrollbarWidth = window.innerWidth - root.offsetWidth + "px";
				root.style.height = "";
				root.style.overflow = "";

				root.style.setProperty("--scrollbarWidth", scrollbarWidth);
				//console.log(scrollbarWidth )
			},
			fetchAll(finalCallback) {
				let thingsToLoad = 3;

				const runCallback = () => {
					--thingsToLoad;

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if (!thingsToLoad && this._.isFunction(finalCallback))
						finalCallback();
				};

				// fetch home page
				this.restHandler__fetch({
					action: "GET",
					route: "/wp-json/mh/v1/posts",
					params: {
						slug: "home"
					},
					callback: response => {
						this.post = response.data.result[0];

						runCallback();
					}
				});

				// fetch news
				this.restHandler__fetch({
					action: "GET",
					route: "/wp-json/mh/v1/posts",
					params: {
						postType: "news_post"
					},
					callback: response => {
						this.newsPosts = response.data.result;

						runCallback();
					}
				});

				// fetch events
				this.restHandler__fetch({
					action: "GET",
					route: "/wp-json/mh/v1/events",
					params: {},
					callback: response => {
						this.eventPosts = response.data.result;

						runCallback();
					}
				});
			}
		},
		mounted() {
			this.fetchAll(() => {
				//console.log( this.$options.name, '• fetchAll() • finalCallback()')

				this.isContentReady = true;
			});
		}
	};
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.RbHomeView {
		.RbContentModules {
			padding-bottom: 0.5em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
