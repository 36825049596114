<template>
	<div class="App flex flex--column maxWidth maxWidth--app font font--sans font--sizeDefault" lang="de">

		<!--
		/**
		* @license
		* MyFonts Webfont Build ID 714760
		*
		* The fonts listed in this notice are subject to the End User License
		* Agreement(s) entered into by the website owner. All other parties are
		* explicitly restricted from using the Licensed Webfonts(s).
		*
		* You may obtain a valid license from one of MyFonts official sites.
		* http://www.fonts.com
		* http://www.myfonts.com
		* http://www.linotype.com
		*
		*/
		-->

		<MhRouterViewWrapper
			:viewKey="$route.fullPath"
		></MhRouterViewWrapper>

		<div class="flex flex--grow"></div>

		<RbAppFooter
			:textLeft="_.get( acfOptions, 'footerTextLeft' )"
			:textRight="_.get( acfOptions, 'footerTextRight' )"
		></RbAppFooter>

		<MhNotification class="hSpace hSpace--app"></MhNotification>

		<!--
		<RbShopDebug></RbShopDebug>
		-->

		<div class="gridDebug grid grid--colGap grid--cols-12 maxWidth maxWidth--app hSpace hSpace--app">
			<div></div><div></div><div></div>
			<div></div><div></div><div></div>
			<div></div><div></div><div></div>
			<div></div><div></div><div></div>
		</div>

		<MhDelegateLinks></MhDelegateLinks>

		<MhDevInfos :showOnHosts="['localhost', 'rotorbooks-dev', 'dev.rotorbooks.de']"></MhDevInfos>
	</div>
</template>

<script>
	// @ is an alias to /src
	import resize from "vue-resize-directive";
	import { EventBus } from "@/event-bus.js";

	//import RestHandler from "@/components/RestHandler/RestHandler.js"
	import RestHandler from "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js"
	import MhRouterViewWrapper from "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterViewWrapper.vue"

	import RbAppHeader from "@/components/RbAppHeader.vue"
	import RbAppMenu from "@/components/RbAppMenu.vue"
	import RbAppFooter from "@/components/RbAppFooter.vue"
	import RbShopDebug from "@/components/RbShop/RbShopDebug.vue"
	import MhNotification from "@/components/MhNotification/v1/MhNotification.vue"
	import MhDevInfos from "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDevInfos/v2/MhDevInfos.vue"
	import MhDelegateLinks from "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDelegateLinks/v5/MhDelegateLinks.vue"

	export default {
		name: "App",
		components: {
			MhDevInfos,
			MhDelegateLinks,
			MhRouterViewWrapper,
			MhNotification,
			RbAppHeader,
			RbAppMenu,
			RbAppFooter,
			RbShopDebug
		},
		directives: {
			resize
		},
		mixins: [RestHandler],
		data() {
			return {
				isReady: false,
				monthNames: [
					"Januar",
					"Februar",
					"März",
					"April",
					"Mai",
					"Juni",
					"Juli",
					"August",
					"September",
					"Oktober",
					"November",
					"Dezember"
				],
				acfOptions: undefined
			};
		},
		computed: {},
		methods: {
			getHostname() {
				// for hidding the devInfos on some environments
				return window.location.hostname;
			},
			getLinkWithoutHostname(url) {
				let theReturn = "";

				try {
					let _url = new URL(url);
					theReturn = _url.pathname;
				} catch (e) {
					//
				}

				return theReturn;
			},
			fetchAll(finalCallback) {
				let thingsToLoad = 1;

				const runCallback = () => {
					--thingsToLoad;

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if (!thingsToLoad && this._.isFunction(finalCallback))
						finalCallback();
				};

				this.restHandler__fetch({
					action: "GET",
					route: "/wp-json/mh/v1/acfOptions",
					params: {},
					callback: response => {
						this.acfOptions = response.data.result;

						runCallback();
					}
				});
			}
		},
		mounted() {
			this.fetchAll(() => {
				//console.log( this.$options.name, '• fetchAll() • finalCallback()')

				this.isReady = true;
			});

			//this.location = location

			/*
									this.$router.afterEach((to, from) => {
										console.group('this.$router.afterEach(to, from)')
										console.log('	from', from)
										console.log('	to  ', to)
										console.groupEnd()
									})
									*/

			// fetch example
			/*
									this.restHandler__fetch({
										action : 'GET',
										route : '/wp-json/mh/v1/acfOptions',
										params : {},
										callback : (response) => {
											this.acfOptions = response.data.result

											// hier wird aus allen introImages eins zufällig ausgewählt
											this.acfOptions.introImage = this._.sample( this.acfOptions.introImages )

											// um das layout handeln zu können sind die beiden
											// ersten menüpunkte, news + info, fest ins system integriert
											this.acfOptions.mainMenu__row1.unshift({
												title__de: "Info",
												title__en: "Info",
												type: "toggleSecondRow",
												page: false
											})
											this.acfOptions.mainMenu__row1.unshift({
												title__de: "News",
												title__en: "News",
												type: "homeView",
												page: false
											})

											this.$set(this.acfOptions, 'translations2', this.translations )
										},
									})
									*/

			// after route change: scroll app__body to top
			/*
									const appBody = document.querySelector('.app__body')
									this.$router.afterEach( (to, from) => {
										appBody.scrollTo(0, 0)
									})
									*/

			// EventBus example
			/*
									EventBus.$on('appHeaderResized', elmHeight => {
										console.log( elmHeight )
									})
									*/
		}
	};
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import "@/less/atoms.less";
	@import "/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/FormField.less";
	//@import "./assets/fonts/mario.css";
	//@import "./assets/fonts/MyFontsWebfontsKit/mario.css";
	@import "./assets/fonts/MyFontsWebfontsKit/MyFontsWebfontsKit.css";

	html {
		.customScrollbars;

		font-size: 16px;
		line-height: 1.2em;
		color: @swatches[textBlack];
		background-color: @swatches[bgWhite];
	}
	body {
		//max-width: 100vw;
		//overflow-x: hidden;
		//overflow-y: auto;
	}
	strong,
	b {
		.font--bold;
	}
	button {
		cursor: pointer;
	}
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		margin: 0;
	}
	a {
		cursor: pointer;
		color: blue;
		text-decoration: none;
	}
	pre {
		@color: black;

		position: relative;
		padding: 0.5em;
		width: 100%;
		min-height: 2em;

		margin-bottom: 0.5em;
		&:first-of-type {
			margin-top: 1em;
		}
		&:last-of-type {
			margin-bottom: 1em;
		}

		//color: fade(@color, 65);
		tab-size: 4;
		white-space: pre;

		outline: 1px solid fade(@color, 25);
		background-color: fade(@color, 5);
		overflow: auto;
		//display: none;

		// label
		&[name]:after {
			position: absolute;
			top: 0;
			right: 0;
			background-color: fade(@color, 15);
			font-size: 13px;
			line-height: 1em;
			font-family: sans-serif;
			color: fade(@color, 75);
			padding: 0.25em 0.5em;
			content: attr(name);
			font-family: inherit;
		}

		&:first-child {
			margin-top: 0em;
		}

		&[maxHeight] {
			max-height: 250px;
		}
	}
	hr {
		border: none;
		border-top: 1px solid;
		margin: 0.5em 0;
		opacity: 0.25;
	}
	ul,
	ol {
		margin-left: 1.5em;
	}

	.view {
		//background-color: fade( red, 10 );

		&__inner {
			//padding: 1rem;
		}
	}
	.gridDebug {
		[showborders1] & {
			visibility: visible;
		}

		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		min-height: 100vh;
		z-index: 100;
		pointer-events: none;
		outline: 1px solid fade(cyan, 35);
		visibility: hidden;
		margin: 0 auto;

		& > * {
			background-color: fade(cyan, 35);
			outline: 1px solid cyan;
		}
	}
	.rbBorder {
		border-width: 2px;

		&--light {
			border-width: 1px;
		}
		&--strong {
			border-width: 4.5px;

			@media @mq[md] {
				border-width: 3px;
			}
		}
		&--top {
			border-top-style: solid;
		}
		&--bottom {
			border-bottom-style: solid;
		}
	}
	.text {
		&--left { text-align: left; }
		&--right { text-align: right; }
	}
	.height {
		&--appHeaderRow {
			height: 185px;

			@media @mq[md] {
				height: 105px;
			}
		}
		&--sansRow {
			height: @app[sansRowHeightDt];

			@media @mq[md] {
				height: @app[sansRowHeightMd];
			}
		}
	}
	.handleHyphens {
		//color: red;
		hyphens: auto;
	}
	.customScrollbars {
		/* width */
		&::-webkit-scrollbar {
			width: 15px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			//box-shadow: inset 0 0 5px grey;
			border-radius: 10px;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			transition: background 0.35s ease;
			background: fade(@swatches[textGold], 100);
			//border: 4px solid fade(black, 5);
			border: 4px solid white;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: fade(black, 95);
		}

		// hide scrollbars an AppBody sliding to view
		.AppBody--isAnimating &::-webkit-scrollbar-thumb {
			background: black;
		}
	}
	.richText {
		a {
			transition: all @transitions[linkTransitionDuration] ease;
			color: @swatches[textGold];
			//text-decoration: underline;
			&:not(.link--noUnderline):hover {
				color: @swatches[textGold];
				text-decoration: underline;
			}
		}
		ul,
		ol {
			margin-left: 1em;
		}
	}
	.noSelect {
		user-select: none;
	}

	.font--sans {
		//font-family: 'Arial', sans-serif;
		font-family: 'Standard', sans-serif;
	}
	.font--serif {
		//font-family: 'Times', sans-serif;
		font-family: 'OptimaLTPro-Roman', serif;

		em, i { font-family: 'OptimaLTPro-Italic', serif; }
	}
	.font--sizeHuge {		// logo
		font-size: 85px;
		line-height: 85px;

		@media @mq[md] {
			font-size: 50px;
			line-height: 50px;
		}
	}
	.font--sizeBig {
		&.font--sans {
			font-size: 58px;
			line-height: 70px;

			font-size: 52px;
			line-height: 58px;

			@media @mq[md] {
				font-size: 32px;
				line-height: 40px;
			}
		}
		&.font--serif {
			font-size: 60px;
			line-height: 70px;

			font-size: 54px;
			line-height: 60px;

			@media @mq[md] {
				font-size: 34px;
				line-height: 40px;
			}
		}

	}
	.font--sizeMedium { 	// 30/30
		&.font--sans {
			font-size: 30px;
			line-height: 30px;

			@media @mq[md] {
				font-size: 26px;
				line-height: 26px;
			}
		}
	}
	.font--sizeDefault {	// 26/36 bzw 25/34
		&.font--sans {
			font-size: 24px;
			line-height: 34px;

			@media @mq[md] {
				font-size: 18px;
				line-height: 24px;
			}
		}
		&.font--serif {
			font-size: 25px;
			line-height: 34px;
			letter-spacing: 0.015em;

			@media @mq[md] {
				font-size: 18px;
				line-height: 24px;
			}
		}

		@media @mq[md] {}
		@media @mq[sm] {}
		@media @mq[xs] {}
	}
	.font--sizeSmall {		// 18/26
		//color: orange !important;
		&.font--sans {
			font-size: 18px;
			line-height: 26px;
			font-size: 14px;
			line-height: 20px;

			@media @mq[md] {
				font-size: 12px;
				line-height: 18px;
			}
		}
	}
	.font--sizeMini {}

	.App {
		min-height: 150vh; // keep the footer below the fold, the easy way
		margin: 0 auto;
	}

	.App .BaseCheckbox {
		--padding: 0em;
		--height: 1em;
		--width: 1em;
		--borderWidth: 0.12em;
		--borderOpacity: 0.5;
		--borderOpacity-hover: 1;

		color: @swatches[textGold];
	}
	.App .BaseRadio {
		--padding: 0em;
		--height: 1em;
		--width: 1em;
		--borderWidth: 0px;
		--bgColor: transparent;

		color: @swatches[textGold];
	}
	.App .BaseText,
	.App .BaseTextarea {
		//--height             : 2em;
		--width: 100%;
		--padding: 0.5em;
		--placeholderOpacity: 0.4;
		--fontFamily: inherit;
		--fontWeight: inherit;
		--transition: all 0.15s ease;

		--color: currentColor;
		--colorOpacity: 1;
		--colorOpacity-hover: 1;
		--colorOpacity-focus: 1;
		--colorOpacity-disabled: 0.5;

		--bgColor: @swatches[textGold];
		--bgOpacity: 0.2;
		--bgOpacity-hover: 0.3;
		--bgOpacity-focus: 0.4;
		--bgOpacity-disabled: 0.05;

		--borderRadius: 0em;
		--borderWidth: 1px;
		--borderStyle: solid;
		--borderColor: @swatches[textGold];
		--borderOpacity: 0.1;
		--borderOpacity-hover: 0.25;
		--borderOpacity-focus: 1;
		--borderOpacity-disabled: 0.25;
	}
	.App .BaseButton {
		--transition: all 0.15s ease;
		--height: 2em;
		--paddingLeft: 0.75em;
		--paddingRight: 0.75em;
		--fontFamily: inherit;
		--fontWeight: inherit;
		--borderRadius: 0em;

		--bgColor-filled: lighten(@swatches[textGold], 15);
		--bgColor-filled-hover: lighten(@swatches[textGold], 0);
		--color-filled: @swatches[textBlack];
		--color-filled-hover: @swatches[textBlack];

		//--bgColor-filled-hover: @swatches[bgWhite];
		//--color-filled-hover: lighten(@swatches[textGold], 0);

		--borderWidth-outlined: 0.11em;
		--borderStyle-outlined: solid;
		--borderColor-outlined: lighten(@swatches[textBlack], 15);

		--bgColor-outlined: fade(@swatches[textBlack], 0);
		--bgColor-outlined-hover: fade(@swatches[textGold], 20);
		--bgColor-outlined-active: fade(@swatches[textBlack], 30);
		--color-outlined: lighten(@swatches[textBlack], 15);
		--color-outlined-hover: @swatches[textBlack];

		--transform-active: translateY(2px);
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
