<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="RbAppMenu hSpace hSpace--app" :class="elmClasses">
		<div class="RbAppMenu__inner">
			<!-- desktop only -->
			<div class="RbAppMenu__row height height--sansRow rbBorder--bottom rbBorder--strong"
				 v-if="!$root.isBelowDt">
				<!-- search bar -->
				<div class="RbAppMenu__searchBar">
					<label class="RbAppMenu__searchBarIcon" for="searchBarInput"></label>
					<input class="RbAppMenu__searchBarInput" type="text" id="searchBarInput" v-model="searchStr" v-on:keyup.enter="submitSearch" />
				</div>
				<!-- main nav items -->
				<div class="RbAppMenu__navLinks flex flex--middle flex--spaceBetween font font--sans font--sizeMedium font--uppercase">
					<router-link class="RbAppMenu__navLink noWrap" :to="'/books/'">Books</router-link>
					<router-link class="RbAppMenu__navLink noWrap" :to="'/events/'">Events</router-link>
					<router-link class="RbAppMenu__navLink noWrap" :to="'/about/'">About</router-link>
					<router-link class="RbAppMenu__navLink noWrap" :to="'/cart/'">Cart <span class="RbAppMenu__countCartItems">{{this.countCartItems}}</span></router-link>
					<!--
					-->
				</div>
			</div>
			<!-- mobile only -->
			<a class="RbAppMenu__row RbAppMenu__mobileMenuToggleBtn height height--sansRow rbBorder--bottom rbBorder--strong"
			   @click="isVisibleMobileMenu = !isVisibleMobileMenu"
			   v-if="$root.isBelowDt">
				<span class="RbAppMenu__mobileMenuToggleBtnLabel font font--sans font--sizeMedium font--uppercase color color--textGold">{{mobileMenuLabel}}</span>
				<span class="RbAppMenu__mobileMenuToggleBtnIcon color color--textGold">
					<span class="RbAppMenu__mobileMenuToggleBtnIconLines">
						<span class="RbAppMenu__mobileMenuToggleBtnIconLine"></span>
						<span class="RbAppMenu__mobileMenuToggleBtnIconLine"></span>
						<span class="RbAppMenu__mobileMenuToggleBtnIconLine"></span>
					</span>
				</span>
			</a>
			<div class="RbAppMenu__row RbAppMenu__mobileMenu font--sans font--sizeMedium font--uppercase" v-show-slide="isVisibleMobileMenu">
				<div class="RbAppMenu__mobileMenuLinks richText">
					<router-link class="RbAppMenu__mobileMenuLink noWrap" :to="'/'">Home</router-link>
					<router-link class="RbAppMenu__mobileMenuLink noWrap" :to="'/books/'">Books</router-link>
					<router-link class="RbAppMenu__mobileMenuLink noWrap" :to="'/events/'">Events</router-link>
					<router-link class="RbAppMenu__mobileMenuLink noWrap" :to="'/about/'">About</router-link>
					<router-link class="RbAppMenu__mobileMenuLink noWrap" :to="'/cart/'">Cart <span class="RbAppMenu__countCartItems">{{this.countCartItems}}</span></router-link>
					<router-link class="RbAppMenu__mobileMenuLink noWrap" :to="'/imprint/'">Imprint</router-link>
					<router-link class="RbAppMenu__mobileMenuLink noWrap" :to="'/imprint/'">Privacy</router-link>
					<router-link class="RbAppMenu__mobileMenuLink noWrap" :to="'/agb/'">Agb</router-link>
				</div>
				<span class="block rbBorder rbBorder--bottom rbBorder--strong"></span>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import { EventBus } from '@/event-bus.js'
	import RbShopMixin from './RbShop/RbShop.mixin.js'
	import BaseText from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/BaseText.vue'

	export default {
		name: 'RbAppMenu',
		components: {
			BaseText,
		},
		mixins: [
			RbShopMixin,
		],
		props: {},
		data(){
			return {
				isVisibleMobileMenu : false,
				searchStr : '',
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			mobileMenuLabel(){
				const routeName = this.$route.name
				const path = this.$route.path
				const slug = this.$route.params.slug
				let value = 'Menu'

				/*
				console.log('routeName:', routeName)
				console.log('path:', path)
				console.log('slug:', slug)
				*/

				if( 'RbHomeView' == routeName ) value = 'Home'
				if( 'RbEventsView' == routeName ) value = 'Events'
				if( 'RbCartView' == routeName ) value = 'Cart'
				if( 'RbShopView' == routeName ) value = 'Books'
				if( 'about' == slug ) value = 'About'
				if( 'imprint' == slug ) value = 'Imprint'
				if( 'agb' == slug ) value = 'Agb'

				return value
			},
		},
		methods: {
			submitSearch( e, doLog = false ){
				const searchStr = this._.trim( this.searchStr )

				if( searchStr ){
					if( doLog ) console.log( this.$options.name, ' • searchStr:', searchStr, e )

					this.$router.push('/books/?search=' + searchStr)
				}
			},
		},
		created(){},
		mounted(){
			this.searchStr = this.$route.query.search
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.RbAppMenu { // debug
		[showBorders2] & {
			background-color: fade( red, 25 );
			.label('RbAppMenu', red );
			//font-size: 4em;
			//line-height: 1em;

			&__row {
				background-color: fade( red, 25 );
			}
			&__searchBar {
				background-color: fade( red, 25 );
			}
		}
	}
	.RbAppMenu { // layout
		position: relative;
		//margin-top: 1rem;

		&__row {
			.grid;
			.grid--cols-2;
			.grid--colGap;

			//height: 60px;
		}
		&__searchBar {
			display: flex;
		}
		&__searchBarIcon {
			width: 2em;
		}
		&__searchBarInput {
			flex-grow: 1;
		}
	}
	.RbAppMenu { // styles
		z-index: 10; // above the content if sticky

		&__searchBarInput {
			.reset--button;
			border: none;
			outline: none;
		}
		&__searchBarIcon {
			background-image: url(./../assets/imgs/search-icon.svg);
			background-size: contain;
			background-repeat: no-repeat;
			border-top: 0.75rem solid transparent;
			border-bottom: 0.75rem solid transparent;
			border-left: 0.35rem solid transparent;
		}

		&__row {
			background-color: fade(@swatches[bgWhite], 100);

			@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
				//background-color: fade(@swatches[bgWhite], 75);
				//backdrop-filter: blur(10px);
			}
		}

		&__navLinks {}
		&__navLink {
			transition: all @transitions[linkTransitionDuration] ease;
			color: inherit;
			overflow: hidden;
			text-overflow: ellipsis;

			&.routerLink--active {
				color: @swatches[textGold];
			}

			&:hover { color: @swatches[textGold]; }
		}
		&__countCartItems {
			//background-color: yellow;

			display: inline-flex;
			justify-content: space-between;
			min-width: 1.5em;

			&::before { content: "("; }
			&::after  { content: ")"; }
		}
	}

	.RbAppMenu__mobileMenuToggleBtn {
		//background-color: fade( red, 20);
		color: inherit;
		display: flex;
		justify-content: space-between;

		&Label, &Icon {
			//background-color: fade( red, 20);
			display: flex;
			align-items: center;
		}
		&Icon {
			&Lines {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 50%; width: 2em;
			}
			&Line {
				background-color: currentColor;
				height: 3px;
				border-radius: 2px;
			}
		}

	}
	.RbAppMenu__mobileMenu {
		//background-color: @swatches[textGold];
		display: flex;
		flex-direction: column;

		&Link {
			display: block;
			padding: 0.25em 0;
			text-decoration: none !important;

			&.routerLink--exactActive { color: @swatches[textBlack]; }
		}
		&Link:first-child {
			padding-top: 0.5em;
		}
		&Link:last-child {
			padding-bottom: 0.5em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
