/**
 *	In dieser Datei können für verschiedene Environments,
 *  basierend auf dem jeweiligen hostname, angelegt werden.
 *  Für die verschiedeneen Environments können Key-Value-Pairs
 *  hinterlegt werden.
 *
 *  Das ist z.B. sinnvoll, wenn die locale Dev-Umgebung
 *  eine andere Rest-API benötigt als die Live-Umgebung.
 */

export default [
	{
		name           : 'local',
		hostname       : 'localhost',
	},
	{
		name           : 'local2',
		hostname       : 'rotorbooks.local',
	},
	{
		name           : 'stage',
		hostname       : 'dev.rotorbooks.de',
	},
	{
		name           : 'live',
		hostname       : 'rotorbooks.de',
	},
]
