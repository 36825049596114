<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<RbContentModules></RbContentModules>

	2019-06-08	init

-->

<template>
	<div class="RbContentModules" :class="elmClasses" v-if="modules">
		<!-- contentModules -->
		<div v-for="(cm, index) in theContentModules"
			:key="index"
			:is="getComponentName(cm)"
			:name="getComponentName(cm)"
			v-bind="getComponentBind(cm)"
		></div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	import RbText from './RbText.vue'
	import RbSlider from './RbSlider.vue'
	import RbSpacer from './RbSpacer.vue'

	export default {
		name: 'RbContentModules',
		components: {
			RbText,
			RbSlider,
			RbSpacer,
		},
		mixins: [],
		props: {
			modules: {
				type     : [Array, Boolean],
				default  : false,
			},
		},
		data(){
			return {
				prefix : 'Rb'
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			theContentModules(){
				const modules = this._.isArray( this.modules ) ? this.modules : []
				const spacerModule = { 'acf_fc_layout' : 'spacer' }
				let theContentModules = []

				modules.forEach((cm, i)=>{
					// add spacer before first text module
					if( i == 0 && cm.acf_fc_layout == 'text' ){
						theContentModules.push( spacerModule )
					}
					// add the module
					theContentModules.push( cm )
					// add spacer after each module
					theContentModules.push( spacerModule )
				})

				return theContentModules
			},
		},
		methods: {
			getComponentName( obj ){
				return this.prefix + this._.upperFirst( obj['acf_fc_layout'] )
			},
			getComponentBind( obj ){
				return obj
			},
		},
		created(){
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.RbContentModules { // debug
		[showBorders2] & > * {
			outline: 1px solid red;
			position: relative;
			background-color: fade( red, 25 );
			.label( attr(name), red );
		}
	}
	.RbContentModules {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
