<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<RbNewsletterWidget></RbNewsletterWidget>

	2019-06-08	init

-->

<template>
	<div class="RbNewsletterWidget hSpace hSpace--app" :class="elmClasses">
		<br/><br/><br/>
		<div class="RbNewsletterWidget__inner rbBorder rbBorder--top rbBorder--bottom">
			<div class="RbNewsletterWidget__left">
				<h2 class="font--serif font--sizeBig">
					Newsletter
				</h2>
			</div>
			<div class="RbNewsletterWidget__right">
				<div class="RbNewsletterWidget__form">
					<BaseText
						:value="mailAddress"
						:placeholder="'E-Mail Adresse'"
						:required="false"
						:disabled="false"
						:hasClearButton="true"
						:debounce="false"
						:debounceDelay="250"
						@input="(e) => { mailAddress = e }"
					></BaseText>
					<BaseButton @click.native="submitForm" :showSpinner="isSubmitting">
						<template slot="before"></template>
						<template slot="default">OK</template>
						<template slot="after"></template>
					</BaseButton>
				</div>
				<div class="RbNewsletterWidget__text font font--sans font--sizeSmall">
					<p>
						Ja, ich möchte Informationen zu den geplanten Veranstaltungen erhalten.
					</p>
					<p>
						Datenschutzerklärung: Eure Email-Adresse wird auf unserem Server gespeichert.
						Wir setzen diese Daten ausschließlich für den Versand des Newsletters ein.
						Wir geben keine Daten an Dritte weiter.
					</p>
				</div>
			</div>
		</div>
		<!--
		<pre>{{mailAddress}}</pre>
		-->
	</div>
</template>

<script>
	import { EventBus } from '@/event-bus.js'
	import RestHandler from "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js"
	import BaseButton from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/BaseButton.vue'
	import BaseText from '/Users/Mario/Dropbox/htdocs/2020-09-17__MhForm/vue-cli-dev/src/components/MhForm/v4/BaseText.vue'

	function validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	export default {
		name: 'RbNewsletterWidget',
		components: {
			BaseText,
			BaseButton,
		},
		mixins: [
			RestHandler,
		],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			someProp: {
				type     : [String, Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {
				mailAddress : '',
				isSubmitting : false,
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			submitForm( doLog = true ){
				const mailAddress = this.mailAddress
				const isValidMail = validateEmail( mailAddress )

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• submitForm()' )
					console.log('mailAddress:', mailAddress)
					console.log('isValidMail:', isValidMail)
					console.groupEnd()
				}

				// submit the mail address via rest
				if( isValidMail ){
					this.isSubmitting = true

					this.restHandler__fetch({
						action : 'POST',
						route : '/wp-json/mh/v1/newsletterSubscription',
						params : {
							mailAddress : mailAddress,
						},
						callback : (response) => {
							//console.log('response:', response)

							this.isSubmitting = false
							this.mailAddress = ''

							EventBus.$emit('MhNotification--show', 'Die Mail-Adresse wurde erfolgreich übermittelt.')
						},
					})
				}
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.RbNewsletterWidget { // debug
		[showBorders2] & {
			&__inner {
				background-color: fade(red, 20);
				.label('RbNewsletterWidget', red );
			}
		}
	}
	.RbNewsletterWidget { // layout
		position: relative;

		&__inner {
			position: relative;
			.grid;
			.grid--gap;
			.grid--cols-12;
		}

		&__form { display: flex; }

		@media @mq[dt] {
			&__left { grid-column: span 5; }
			&__right { grid-column: span 7; }
		}
		@media @mq[md] {
			&__left { grid-column: span 12; }
			&__right { grid-column: span 12; }
		}
	}
	.RbNewsletterWidget { // styling
		&__inner {
			padding-top: 1.5rem;
    		padding-bottom: 1.5rem;
		}
		&__form {
			padding-bottom: 1rem;
		}
		&__text p {
			padding-bottom: .5rem;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
