/*
	@changelog:
		2021-08-02	init

*/

import _ from 'lodash'

export default {
	state: {
		cartItems: [],
		customer: {
			acceptedLegals: false,
			acceptedPrivacy: false,
			hasNewsletterSubscription: false,
			shippingOption: '',
			paymentOption: 'invoice',
			invoiceAddress: {
				company: '',
				name: '',
				street: '',
				zip: '',
				city: '',
				country: '',
				mail: '',
				phone: '',
				message: '',
			},
			hasDifferentDeliveryAddress: false,
			deliveryAddress: {
				company: '',
				name: '',
				street: '',
				zip: '',
				city: '',
				country: '',
				mail: '',
				phone: '',
				message: '',
			},
		},
		customerFields: [
			{ label: 'Unternehmen', key: 'company', required: false, type: 'text', },
			{ label: 'Name', key: 'name', required: true, type: 'text' },
			{ label: 'Strasse', key: 'street', required: true, type: 'text', },
			{ label: 'PLZ', key: 'zip', required: true, type: 'text' },
			{ label: 'Stadt', key: 'city', required: true, type: 'text' },
			{ label: 'Land', key: 'country', required: true, type: 'text' },
			{ label: 'Mail', key: 'mail', required: true, type: 'text' },
			{ label: 'Telefon', key: 'phone', required: false, type: 'text', },
			{ label: 'Nachricht', key: 'message', required: false, type: 'textarea', },
		],
		shippingOptions: [
			{
				label: 'Lieferung innerhalb Leipzigs',
				key: 'city',
				desc: 'kostenloser Fahrradservice',
			},
			{
				label: 'Versand nach Deutschland',
				key: 'germany',
				desc:
					'ab 18,00&nbsp;€ Bestellwert kostenlos, unter 18,00&nbsp;€ pauschal 3,00&nbsp;€ für Porto und Verpackung',
			},
			{
				label: 'Versand nach Europa',
				key: 'europe',
				desc: 'pauschal 8,50&nbsp;€',
			},
		],
		paymentOptions: [
			{
				label: 'Kauf auf Rechnung',
				key: 'invoice',
				desc:
					'Die Ware wird zusammen mit der Rechnung versendet.	Bitte begleichen Sie den Rechnungsbetrag erst nach Erhalt der Rechnung.',
			},
		],
	},
	getters: {
		countCartItems: (state, getters) => {
			const amounts = _.map(state.cartItems, 'amount')
			let countItems = 0

			// addiere alle amounts
			amounts.forEach((amount) => {
				countItems = countItems + amount
			})

			return countItems
		},
		cartItems: (state, getters) => {
			return state.cartItems
		},
		customer: (state, getters) => {
			return state.customer
		},
		customerFields: (state, getters) => {
			return state.customerFields
		},
		shippingOptions: (state, getters) => {
			return state.shippingOptions
		},
		paymentOptions: (state, getters) => {
			return state.paymentOptions
		},
		productsBruttoPrice: (state, getters) => {
			let value = 0

			state.cartItems.forEach((item) => {
				value = value + item.product.bruttoPrice * item.amount
			})

			return _.round(value, 2)
		},
		productsNettoPrice: (state, getters) => {
			let value = 0

			state.cartItems.forEach((item) => {
				value = value + item.product.nettoPrice * item.amount
			})

			return _.round(value, 2)
		},
		productsTaxesPrice: (state, getters) => {
			let value = 0

			value = getters.productsBruttoPrice - getters.productsNettoPrice

			return _.round(value, 2)
		},
		shippingNettoPrice: (state, getters) => {
			const shippingTaxesPercantage = getters.shippingTaxesPercantage
			const shippingNettoPrice = _.round(getters.shippingBruttoPrice / (shippingTaxesPercantage / 100 + 1), 2)

			return shippingNettoPrice
		},
		shippingTaxesPercantage: (state, getters) => {
			const cartItems = getters.cartItems
			const productsTaxPercantages = _.map(cartItems, 'product.taxPercentage')
			let shippingTaxPercantage = 0
			let taxPercantages = {}

			// prepare the percante>sum object
			productsTaxPercantages.forEach(taxPercantage => {
				taxPercantages[taxPercantage] = 0
			})

			// find the correct shipping taxPercantage:
			// der steuersatz für den versand orientiert sich am steuersatz der hauptleistung
			// wenn also mehr produkte mit 7% verkauft wurden ist er 7%
			// wenn mehr produkte mit 19% verkauft wurden ist er 19%
			cartItems.forEach(cartItem => {
				const productAmount = cartItem.amount
				const productTaxPercentage = cartItem.product.taxPercentage
				const productNettoPrice = cartItem.product.nettoPrice

				const currentPrice = taxPercantages[productTaxPercentage]
				taxPercantages[productTaxPercentage] = currentPrice + (productAmount * productNettoPrice)

				/*
				console.log('productAmount:', productAmount)
				console.log('productTaxPercentage:', productTaxPercentage)
				console.log('productNettoPrice:', productNettoPrice)
				*/
			})

			// which is the highest?
			let highestPrice = 0
			_.forEach(taxPercantages, (sum, taxPercantage) => {
				//console.log(taxPercantage, sum)
				if (sum > highestPrice) {
					shippingTaxPercantage = taxPercantage
					highestPrice = sum
				}
			})

			/*
			console.log('productsTaxPercantages:', productsTaxPercantages)
			console.log('taxPercantages:', taxPercantages)
			console.log('shippingTaxPercantage:', shippingTaxPercantage)
			*/

			return shippingTaxPercantage
		},
		shippingTaxesPrice: (state, getters) => {
			const shippingBruttoPrice = getters.shippingBruttoPrice
			const shippingTaxPercantage = getters.shippingTaxesPercantage
			const shippingNettoPrice = _.round(getters.shippingBruttoPrice / (shippingTaxPercantage / 100 + 1), 2)

			/*
			console.log('shippingBruttoPrice:', shippingBruttoPrice)
			console.log('shippingTaxPercantage:', shippingTaxPercantage)
			*/

			return shippingBruttoPrice - shippingNettoPrice
		},
		shippingBruttoPrice: (state, getters) => {
			const shippingOption = getters.getCustomerProp('shippingOption')
			const hasCartItems = _.size( getters.cartItems ) ? true : false
			let value = 0

			if ('leipzig' === shippingOption) value = 0
			if ('germany' === shippingOption) value = getters.productsBruttoPrice > 18 ? 0 : 3
			if ('europe' === shippingOption) value = 8.5

			if( !hasCartItems ) value = 0

			return value
		},
		totalNettoPrice: (state, getters) => {
			const shippingNettoPrice = getters.shippingNettoPrice
			const productsNettoPrice = getters.productsNettoPrice
			let value = 0

			value = _.round( shippingNettoPrice + productsNettoPrice, 2 )

			return value
		},
		totalTaxesPrice: (state, getters) => {
			const shippingTaxesPrice = getters.shippingTaxesPrice
			const productsTaxesPrice = getters.productsTaxesPrice
			let value = 0

			value = _.round(shippingTaxesPrice + productsTaxesPrice, 2)

			/*
			console.log('shippingTaxesPrice:', shippingTaxesPrice)
			*/

			return productsTaxesPrice + shippingTaxesPrice
		},
		totalBruttoPrice: (state, getters) => {
			const shippingBruttoPrice = getters.shippingBruttoPrice
			const productsBruttoPrice = getters.productsBruttoPrice
			let value = 0

			value = _.round(shippingBruttoPrice + productsBruttoPrice, 2)

			return value
		},
		getCustomerProp: (state, getters) => (key) => {
			return _.get(state.customer, key)
		},
		isStepValid: (state, getters) => (stepSlug, doLog = false) => {
			let validations = []
			const hasCartItems = _.size( getters.cartItems ) ? true : false
			const hasShippingOption = _.isEmpty( getters.getCustomerProp('shippingOption') ) ? false : true
			const hasPaymentOption = _.isEmpty( getters.getCustomerProp('paymentOption') ) ? false : true
			const hasDifferentDeliveryAddress = getters.getCustomerProp('hasDifferentDeliveryAddress')
			const hasAcceptedLegals = getters.getCustomerProp('acceptedLegals')
			const hasAcceptedPrivacy = getters.getCustomerProp('acceptedPrivacy')

			if( 'cart' == stepSlug ){
				//doLog = false
				validations.push( hasCartItems )
			}
			if( 'customer' == stepSlug ){
				//doLog = true
				validations.push( hasShippingOption )
				validations.push( hasPaymentOption )

				// validate invoice address
				getters.customerFields.forEach( (field)=>{
					const isRequired = field.required
					const isEmpty = _.isEmpty( getters.getCustomerProp('invoiceAddress.'+field.key) )

					if( isRequired ){
						/*
						console.log(
							'field:',
							field.key,
							field.required,
							isEmpty
						)
						*/

						if( isEmpty ) validations.push( false )
					}
				})
				if( hasDifferentDeliveryAddress ){
					getters.customerFields.forEach( (field)=>{
						const isRequired = field.required
						const isEmpty = _.isEmpty( getters.getCustomerProp('deliveryAddress.'+field.key) )

						if( isRequired ){
							/*
							console.log(
								'field:',
								field.key,
								field.required,
								isEmpty
							)
							*/

							if( isEmpty ) validations.push( false )
						}
					})
				}
			}
			if( 'finish' == stepSlug ){
				validations.push( getters.isStepValid('cart') )
				validations.push( getters.isStepValid('customer') )
				validations.push( hasAcceptedLegals )
				validations.push( hasAcceptedPrivacy )
			}

			// groupCollapsed group
			if (doLog) {
				console.group('store.js • isStepValid( stepSlug )')
				console.log('stepSlug:', stepSlug)
				console.log('hasCartItems:', hasCartItems)
				console.log('hasShippingOption:', hasShippingOption)
				console.log('hasPaymentOption:', hasPaymentOption)
				console.log('validations:', validations)
				console.groupEnd()
			}

			return validations.includes( false ) ? false : true
		},
	},
	mutations: {
		addCartItem(state, args, doLog = false) {
			const product = args.product
			const amount = args.amount
			const productId = product.id
			const productInCart = state.cartItems.find((item) => { return item.product.id === productId })
			const isAlreadyInCart = _.isObject(productInCart)

			// groupCollapsed group
			if (doLog) {
				console.groupCollapsed('store.js • addCartItem( args )')
				console.log('args:', args)
				console.log('productId:', productId)
				console.log('state:', state)
				console.log('productInCart:', productInCart)
				console.log('isAlreadyInCart:', isAlreadyInCart)
				console.groupEnd()
			}

			// product ist schon im cart: erhöhe amount
			if (isAlreadyInCart) {
				productInCart.amount++
			}
			// product ist noch nicht im cart: füge es hinzu
			else {
				state.cartItems.push({
					product : product,
					amount : amount,
				})
			}
		},
		removeCartItem(state, args, doLog = false) {
			const itemId = args.itemId
			const itemIndex = _.findIndex(state.cartItems, (item) => {
				return item.product.id === itemId
			})

			// groupCollapsed group
			if (doLog) {
				console.groupCollapsed('store.js • removeCartItem( itemId )')
				console.log('args:', args)
				console.log('itemId:', itemId)
				console.log('itemIndex:', itemIndex)
				console.groupEnd()
			}

			state.cartItems.splice(itemIndex, 1)
		},
		changeCartItemAmount(state, args, doLog = false) {
			const itemId = args.itemId
			const item = _.find(state.cartItems, (item) => {
				return item.product.id === itemId
			})

			// groupCollapsed group
			if (doLog) {
				console.groupCollapsed('store.js • changeCartItemAmount( itemId )')
				console.log('args:', args)
				console.log('itemId:', itemId)
				console.log('item:', item)
				console.groupEnd()
			}

			if (item) item.amount = item.amount + args.step
		},
		setCustomerProp(state, args, doLog = false) {
			const key = args.key
			const value = args.value
			const hasKey = _.has(state.customer, key)

			// groupCollapsed group
			if (doLog) {
				console.groupCollapsed('store.js • setCustomerProp( args )')
				console.log('key:', key)
				console.log('value:', value)
				console.log('hasKey:', hasKey)
				console.groupEnd()
			}

			if (hasKey) {
				_.set(state.customer, key, value)
			}
		},
	},
}
