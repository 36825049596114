<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<RbSlider></RbSlider>

	2019-06-08	init

-->

<template>
	<div class="RbSlider hSpace--app" :class="elmClasses">
		<div class="RbSlider__inner">
			<VueperSlides class="no-shadow" :dragging-distance="70" prevent-y-scroll :slideRatio="slideRatio">
				<VueperSlide v-for="(image, index) in images" :key="index">
					<template v-slot:content>
						<MhImage :imageObject="image" :mode="'contain'"></MhImage>
					</template>
				</VueperSlide>
			</VueperSlides>
		</div>

		<!--
			<pre name="images" maxHeight>{{images}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { VueperSlides, VueperSlide } from 'vueperslides'
	import 'vueperslides/dist/vueperslides.css'

	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'RbSlider',
		components: {
			VueperSlides,
			VueperSlide,
			MhImage,
		},
		mixins: [],
		props: {
			images: {
				type     : [Array, Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			slideRatio(){
				const firstImage = this._.get( this.images, '[0]', false )
				const width = parseInt( this._.get( firstImage, 'width', 16 ) )
				const height = parseInt( this._.get( firstImage, 'height', 9 ) )
				const ratio = (height/width)

				/*
				console.log('firstImage:', firstImage)
				console.log('width:', width)
				console.log('height:', height)
				console.log('ratio:', ratio)
				*/

				return ratio
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.RbSlider { // debug
		.vueperslides {
			//background-color: fade( red, 25 );
			//outline: 2px solid red;
		}
	}
	.RbSlider { // layout + styling
		.vueperslides__arrows {
			color: @swatches[textGold];
		}
		.vueperslides__arrow {
			svg {
				//background-color: fade( red, 25 );
				width: 3em;
			}
			path { stroke-width: 1.25px; }
		}
		.vueperslides__arrow--prev {
			left: -0.5em;
		}
		.vueperslides__arrow--next {
			right: -0.5em;
		}

		.vueperslides__bullets {
			//background-color: fade( red, 25 );
			z-index: 2;
			color: @swatches[textGold];
		}
		.vueperslides__bullet {
			margin: 0;
			padding: 0.5em 0.25em;

			.default {
				box-shadow: 0 0 1px rgb(0 0 0 / 50%), 0 0 3px rgb(0 0 0 / 30%);
				//box-shadow: none;
				width: 12px; height: 12px;
				border-width: 2px;
			}
		}
		.vueperslides__bullet--active {
			.default { border-width: 6px; }
		}

		.MhImage {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
